import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'account-activation/:id',
    loadChildren: () => import('./account-activation/account-activation.module').then( m => m.AccountActivationPageModule)
  },
  {
    path: 'form-reregistration',
    loadChildren: () => import('./form-reregistration/form-reregistration.module').then( m => m.FormReregistrationPageModule)
  },
  {
    path: 'form-reregistration-info/:id',
    loadChildren: () => import('./form-reregistration-info/form-reregistration-info.module').then( m => m.FormReregistrationInfoPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'form-applicant/:id',
    loadChildren: () => import('./form-applicant/form-applicant.module').then( m => m.FormApplicantPageModule)
  },
  {
    path: 'form-submission',
    loadChildren: () => import('./form-submission/form-submission.module').then( m => m.FormSubmissionPageModule)
  },
  {
    path: 'form-applicant-sk',
    loadChildren: () => import('./form-applicant-sk/form-applicant-sk.module').then( m => m.FormApplicantSkPageModule)
  },
  {
    path: 'form-applicant-doc',
    loadChildren: () => import('./form-applicant-doc/form-applicant-doc.module').then( m => m.FormApplicantDocPageModule)
  },
  {
    path: 'payment/:id',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'payment-va/:id',
    loadChildren: () => import('./payment-va/payment-va.module').then( m => m.PaymentVaPageModule)
  },
  {
    path: 'payment-method/:id/:type',
    loadChildren: () => import('./payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'payment-va-bca/:id',
    loadChildren: () => import('./payment-va-bca/payment-va-bca.module').then( m => m.PaymentVaBcaPageModule)
  },
  {
    path: 'payment-cc/:id',
    loadChildren: () => import('./payment-cc/payment-cc.module').then( m => m.PaymentCcPageModule)
  },
  {
    path: 'payment-notif/:id',
    loadChildren: () => import('./payment-notif/payment-notif.module').then( m => m.PaymentNotifPageModule)
  },
  {
    path: 'payment-reregistration/:id',
    loadChildren: () => import('./payment-reregistration/payment-reregistration.module').then( m => m.PaymentReregistrationPageModule)
  },
  {
    path: 'form-applicant-receipt/:id',
    loadChildren: () => import('./form-applicant-receipt/form-applicant-receipt.module').then( m => m.FormApplicantReceiptPageModule)
  },
  {
    path: 'reset-password/:id',
    loadChildren: () => import('./reset-password//reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'payment-va-bca-sprint/:id',
    loadChildren: () => import('./payment-va-bca-sprint/payment-va-bca-sprint.module').then( m => m.PaymentVaBcaSprintPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
