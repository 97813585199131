import { Component } from '@angular/core';
import { LanguageService } from './services/language.service';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { MenuController, Platform } from '@ionic/angular';
import { EventsService } from './services/events.service';
import { ServerApiService } from './services/server-api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Register',
      url: '/register',
      icon: 'create'
    }
  ];

  public userPages = [
    {
      title: 'dashboard',
      url: '/dashboard',
      icon: 'browsers'
    },
    {
      title: 'submission_form',
      url: '/form-submission',
      icon: 'clipboard'
    },
    {
      title: 'reregistration_form',
      url: '/form-reregistration',
      icon: 'document'
    },
    {
      title: 'profile',
      url: '/profile',
      icon: 'person'
    }
  ];  

  constructor(
    public lang: LanguageService,
    public fcm : FirebaseX,
    private platform: Platform,
    public serverApi: ServerApiService,
    public eventService: EventsService,
    public menuCtrl: MenuController,

  ) {
    this.lang.getLanguageFromServer();

    this.menuCtrl.enable(false, "sideMenu");

    // this.menuCtrl.enable(false, "sideMenu").then((res) => {
    //   console.log('menu disabled', res)
    // })
    this.platform.ready().then(() => {
      // this.fcm.subscribeToTopic('evbike');

      if (this.platform.is('cordova')) {
        this.fcm.getToken().then(token => {
          console.log('token=', token)
          this.serverApi.fcm_tokey_key = token;
        });
        
        this.fcm.onMessageReceived().subscribe(data => {
          if(data.wasTapped){
            console.log("Received in background");
          } else {
            console.log("Received in foreground");
          };
        });
        
        this.fcm.onTokenRefresh().subscribe(token => {
          this.serverApi.fcm_tokey_key = token;

          // backend.registerToken(token);
        });
        
        this.fcm.hasPermission().then(hasPermission => {
          if (hasPermission) {
            console.log("Has permission!");
          }
        })

      }

      this.eventService.checkIsLogin(false).then((success) => {},(error) => {});

    });
  }
}
